.btn {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 900;
  background-color: #00a139;
  transition: background-color 200ms ease-in-out;
}
.btn:hover {
  cursor: pointer;
  background-color: #008931;
}
.btn .btn-icon {
  width: 22px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: 0;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header .banner {
  position: relative;
  display: block;
}
.header .banner .bg-image {
  width: 100%;
  height: auto;
}
.header .banner .logo {
  position: absolute;
  top: -20px;
  left: 50px;
}
@media screen and (min-width: 500px) and (max-width: 850px) {
  .header .banner .logo {
    width: 120px;
    top: -10px;
    left: 20px;
  }
}
@media screen and (max-width: 500px) {
  .header .banner .logo {
    width: 80px;
    top: -5px;
    left: 10px;
  }
}
.header .banner .headline {
  position: absolute;
  top: 130px;
  right: 50px;
}
@media screen and (max-width: 500px) {
  .header .banner .headline {
    top: 30px;
    right: 20px;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .header .banner .headline {
    top: 30px;
    right: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .header .banner .headline {
    top: 90px;
  }
}
.header .banner .headline .h-text {
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1607843137);
  text-transform: uppercase;
  font-weight: 900;
  color: #FFFFFF;
  display: block;
}
.header .banner .headline .h-text-small {
  font-size: 38px;
}
@media screen and (max-width: 500px) {
  .header .banner .headline .h-text-small {
    font-size: 16px;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .header .banner .headline .h-text-small {
    font-size: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .header .banner .headline .h-text-small {
    font-size: 26px;
  }
}
.header .banner .headline .h-text-big {
  font-size: 70px;
}
@media screen and (max-width: 500px) {
  .header .banner .headline .h-text-big {
    font-size: 20px;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .header .banner .headline .h-text-big {
    font-size: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .header .banner .headline .h-text-big {
    font-size: 40px;
  }
}
.header .banner .headline .line1 {
  text-align: left;
}
.header .banner .headline .line2 {
  text-align: center;
}
.header .banner .headline .line3 {
  text-align: right;
}
.header .countdown {
  display: block;
  position: relative;
  top: -40px;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .header .countdown {
    top: 0;
  }
}
@media screen and (min-width: 500px) and (max-width: 768px) {
  .header .countdown {
    top: -20px;
  }
}
.header .countdown img {
  width: 40%;
  max-width: 540px;
}
@media screen and (max-width: 768px) {
  .header .countdown img {
    width: 60%;
  }
}
.header .countdown h2 {
  margin-top: -5px;
  margin-bottom: 0;
}
.header .countdown span {
  font-weight: 300;
}

.matches {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.matches .productImageContainer {
  position: relative;
}
.matches .productImageContainerMobile {
  position: relative;
  margin: 0 !important;
}
.matches .productImage {
  display: block;
  filter: grayscale(100%);
}
.matches .headline {
  width: 100%;
}
.matches .headline-desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .matches .headline-desktop {
    display: none;
  }
}
.matches .headline-mobile {
  display: none;
}
.matches .headline-mobile h1 {
  word-wrap: normal;
}
@media screen and (max-width: 768px) {
  .matches .headline-mobile {
    display: block;
  }
}
.matches h1 {
  color: #00a139;
  margin: 0;
}
.matches h2 {
  margin: 0;
}
.matches .sperator {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.matches .sperator hr {
  border-top: 1px solid #3A3A3B;
  margin-left: 10px;
  margin-right: 10px;
}
.matches .sperator .line {
  width: 35%;
}
@media screen and (max-width: 1000px) {
  .matches .sperator .line {
    width: 45%;
  }
}
.matches .match {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  background-color: #F5F5F5;
}
@media screen and (max-width: 850px) {
  .matches .match {
    padding-bottom: 0;
  }
}
.matches .match span {
  display: block;
}
.matches .match .text-desktop {
  display: block;
}
@media screen and (max-width: 768px) {
  .matches .match .text-desktop {
    display: none;
  }
}
.matches .match .text-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .matches .match .text-mobile {
    display: none;
  }
}
.matches .match .matchTime {
  font-weight: 300;
}
.matches .match .encounter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.matches .match .encounter .container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.matches .match .encounter .left {
  width: 45%;
  justify-content: flex-end;
}
.matches .match .encounter .middle {
  width: 10%;
  min-width: 150px;
  justify-content: center;
}
.matches .match .encounter .right {
  width: 45%;
  justify-content: flex-start;
}
.matches .match .encounter .flag {
  height: 25px;
}
.matches .match .encounter .land {
  font-weight: 900;
  margin-left: 10px;
  margin-right: 10px;
}
.matches .match .encounter .counterGoalContainer {
  font-size: 38px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 60px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
}
.matches .match .encounter .colonSeperator {
  font-size: 38px;
  font-weight: 900;
}
@media screen and (max-width: 850px) {
  .matches .match .bountys-desktop {
    display: none;
  }
}
.matches .match .bountys-desktop .bountyBox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
}
@media screen and (max-width: 1000px) {
  .matches .match .bountys-desktop .bountyBox {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.matches .match .bountys-desktop .bountyBox .section {
  padding: calc(2 * padding);
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 80px;
}
.matches .match .bountys-desktop .bountyBox .section .productDescription {
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.matches .match .bountys-desktop .bountyBox .section .productDescription .colorGreen {
  color: #00a139;
}
.matches .match .bountys-desktop .bountyBox .section .productDescription .colorGray {
  color: #bdbdbd;
}
.matches .match .bountys-desktop .bountyBox .section .productDescription .sectionHeadline {
  font-size: 16px;
  font-weight: 900;
}
.matches .match .bountys-desktop .bountyBox .section .productDescription .subText {
  font-weight: 300;
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer .smallText {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 5px;
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer .popup {
  visibility: hidden;
  min-width: 70px;
  background-color: #FFFFFF;
  font-size: 11px;
  top: -20px;
  left: 100px;
  border: 1px solid #EBECEC;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  transform: translateX(-50%);
  font-weight: 300;
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer .popup.show {
  visibility: visible;
  animation: fadeInOut 2s;
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer .codebox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border: 2px dashed #3A3A3B;
  font-weight: 900;
}
.matches .match .bountys-desktop .bountyBox .section .codeContainer .codebox img {
  margin-left: 10px;
  width: 14px;
  height: 18px;
  cursor: pointer;
}
.matches .match .bountys-desktop .bountyBox .hidden {
  visibility: hidden;
}
.matches .match .bountys-desktop .bountyBox .left {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  .matches .match .bountys-desktop .bountyBox .left {
    padding-left: 10px;
  }
}
.matches .match .bountys-desktop .bountyBox .left .ballContainer {
  display: flex;
  align-items: center;
  margin-right: 40px;
}
@media screen and (max-width: 1000px) {
  .matches .match .bountys-desktop .bountyBox .left .ballContainer {
    margin-right: 0;
  }
}
.matches .match .bountys-desktop .bountyBox .right {
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1000px) {
  .matches .match .bountys-desktop .bountyBox .right {
    padding-right: 10px;
  }
}
.matches .match .bountys-desktop .bountyBox .right .ballContainer {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
@media screen and (max-width: 1000px) {
  .matches .match .bountys-desktop .bountyBox .right .ballContainer {
    margin-left: 0;
  }
}
.matches .match .bountys-desktop .bountyBox .left, .matches .match .bountys-desktop .bountyBox .right {
  flex: 1;
  background-color: #FFFFFF;
}
.matches .match .bountys-desktop .bountyBox .center {
  display: flex;
  justify-content: center;
  width: 125px;
  background-color: #FFFFFF;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.matches .match .bountys-desktop .bountyBox .center img {
  width: 115px;
  height: 115px;
}
.matches .match .bountys-mobile {
  display: none;
}
@media screen and (max-width: 850px) {
  .matches .match .bountys-mobile {
    display: block;
  }
}
.matches .match .bountys-mobile .bountyBox {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  align-items: center;
}
.matches .match .bountys-mobile .bountyBox .ballContainer img {
  width: 27px;
  height: 27px;
}
.matches .match .bountys-mobile .bountyBox .productDescription {
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
}
.matches .match .bountys-mobile .bountyBox .productDescription .colorGreen {
  color: #00a139;
}
.matches .match .bountys-mobile .bountyBox .productDescription .colorGray {
  color: #cecece;
}
.matches .match .bountys-mobile .bountyBox .productDescription .sectionHeadline {
  font-size: 13px;
  font-weight: 900;
}
.matches .match .bountys-mobile .bountyBox .productDescription .subText {
  font-size: 13px;
  font-weight: 300;
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
@media screen and (max-width: 500px) {
  .matches .match .bountys-mobile .bountyBox .bottomSectionWrapper {
    flex-direction: column-reverse;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 500px) {
  .matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer {
    margin-top: 10px;
    flex-direction: row;
  }
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .popup {
  visibility: hidden;
  min-width: 70px;
  background-color: #FFFFFF;
  font-size: 11px;
  top: 20px;
  left: 90px;
  border: 1px solid #EBECEC;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  transform: translateX(-50%);
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .popup.show {
  visibility: visible;
  animation: fadeInOut 2s;
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .smallText {
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
  .matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .smallText {
    margin-right: 10px;
    margin-bottom: 0;
  }
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .codebox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  border: 2px dashed #3A3A3B;
  font-weight: 900;
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .codeContainer .codebox img {
  margin-left: 10px;
  width: 14px;
  height: 18px;
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .imageContainer {
  margin-left: 40px;
}
.matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .imageContainer img {
  width: 100px;
  height: 100px;
}
@media screen and (max-width: 500px) {
  .matches .match .bountys-mobile .bountyBox .bottomSectionWrapper .imageContainer {
    margin-left: 0;
  }
}

.footer {
  background-color: #24353F;
  padding: 20px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  font-size: 13px;
}
.footer .inner {
  width: 1240px;
}
@media screen and (max-width: 1280px) {
  .footer .inner {
    width: 100%;
  }
}
.footer .flex-container {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .footer .flex-container {
    flex-direction: column;
  }
}
.footer .flex-container .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}
@media screen and (max-width: 768px) {
  .footer .flex-container .box {
    flex-direction: column;
    width: 100%;
  }
}
.footer .containerLogo {
  width: 85px;
  height: 25px;
  margin-right: 10px;
}
.footer .containerLogo img {
  width: 100%;
  height: 100%;
}
.footer .containerCopyright {
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .footer .containerCopyright {
    margin-top: 10px;
    text-align: center;
  }
}
.footer .containerEnglish {
  margin-right: 10px;
  cursor: pointer;
  width: 35px;
  height: 25px;
}
@media screen and (max-width: 768px) {
  .footer .containerEnglish {
    margin-top: 10px;
  }
}
.footer .containerEnglish a {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .footer .containerDisclaimer {
    margin-top: 10px;
  }
}

.description .top-wrapper {
  display: flex;
  flex-direction: column;
}
.description .top-wrapper .line-top {
  margin-bottom: 40px;
  border-top: 1px solid #3A3A3B;
}
.description .top-wrapper .cols {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 768px) {
  .description .top-wrapper .cols {
    flex-direction: column;
  }
}
.description .top-wrapper .cols .col-1 {
  width: 36%;
}
@media screen and (max-width: 768px) {
  .description .top-wrapper .cols .col-1 {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .description .top-wrapper .cols .col-1 {
    width: 50%;
    padding-left: 20px;
    padding-right: 10px;
  }
}
.description .top-wrapper .cols .col-1 h1 {
  margin: 0;
}
.description .top-wrapper .cols .col-1 .thinText {
  font-weight: 300;
}
.description .top-wrapper .cols .col-2 {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .description .top-wrapper .cols .col-2 {
    display: none;
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .description .top-wrapper .cols .col-2 {
    width: 50%;
  }
}
.description .top-wrapper .cols .col-2 .col-2-inner {
  max-width: 510px;
}
@media screen and (max-width: 768px) {
  .description .top-wrapper .cols .col-2 .col-2-inner {
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .description .top-wrapper .cols .col-2 .col-2-inner {
    max-width: unset;
    padding-left: 10px;
    padding-right: 20px;
  }
}
.description .top-wrapper .cols .col-2 .col-2-inner h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 900;
}
.description .top-wrapper .cols .col-2 .col-2-inner span {
  font-weight: 300;
}
.description .top-wrapper .cols .col-3 {
  width: 19%;
  position: relative;
}
@media screen and (max-width: 1280px) {
  .description .top-wrapper .cols .col-3 {
    width: 0;
  }
}
.description .top-wrapper .cols .col-3 .imageJubeln {
  position: absolute;
  bottom: -30px;
  left: -5px;
}
@media screen and (max-width: 1280px) {
  .description .top-wrapper .cols .col-3 .imageJubeln {
    display: none;
  }
}
.description .flagLineContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}
@media screen and (max-width: 768px) {
  .description .flagLineContainer {
    display: none;
  }
}
.description .flagLineContainer .section {
  width: 33.33%;
  height: 10px;
}
.description .flagLineContainer .black {
  background-color: #000000;
}
.description .flagLineContainer .red {
  background-color: #cb2e25;
}
.description .flagLineContainer .gold {
  background-color: #ffc600;
}
.description .disclaimer {
  font-weight: 300;
  margin-top: 20px;
  color: #707070;
}
@media screen and (max-width: 768px) {
  .description .disclaimer {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .description .disclaimer {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.description .wrapperAccordion {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .description .wrapperAccordion {
    display: none;
  }
}
.description .wrapperAccordion .accordion {
  margin: 0 auto;
  overflow: hidden;
}
.description .wrapperAccordion .teaser {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
}
.description .wrapperAccordion .teaser h2 {
  text-align: center;
  margin: 0;
}
.description .wrapperAccordion .teaser img {
  margin-bottom: 20px;
  margin-top: 5px;
}
.description .wrapperAccordion .teaser p {
  margin: 0;
}
.description .wrapperAccordion .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 120px, rgb(255, 255, 255) 100%);
  pointer-events: none;
}
.description .wrapperAccordion .content {
  display: none;
  padding: 0 20px;
  background: #fff;
  margin-bottom: 50px;
}
.description .wrapperAccordion .flagLineContainerMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}
.description .wrapperAccordion .flagLineContainerMobile .section {
  width: 33.33%;
  height: 10px;
}
.description .wrapperAccordion .flagLineContainerMobile .black {
  background-color: #000000;
}
.description .wrapperAccordion .flagLineContainerMobile .red {
  background-color: #cb2e25;
}
.description .wrapperAccordion .flagLineContainerMobile .gold {
  background-color: #ffc600;
}
.description .wrapperAccordion .disclaimerMobile {
  font-weight: 300;
  margin-top: 20px;
  color: #707070;
}

.bountySlider {
  width: auto;
}
@media screen and (max-width: 1280px) {
  .bountySlider .headline {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.bountySlider .headline h2 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bountySlider .headline .lineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bountySlider .headline .lineContainer .thickLine {
  width: 10%;
  min-width: 100px;
  height: 5px;
  background-color: #3A3A3B;
}
.bountySlider .headline .lineContainer .thinLine {
  width: 90%;
  height: 1px;
  background-color: #3A3A3B;
}
.bountySlider .bounty {
  margin-top: 20px;
}
.bountySlider .owl-stage-outer {
  height: 160;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bountySlider .sliderBackground {
  width: 100%;
  height: 130px;
  position: absolute;
  top: 102px;
  background-image: url("../assets/trikotSliderBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bountySlider .slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bountySlider .slide .trikotImage {
  max-height: 150px;
  width: auto;
  transition: transform 0.3s ease;
  transform-origin: bottom;
}
.bountySlider .slide .trikotImage:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.bountySlider .bountyslider-custom-nav-container-2 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bountySlider .bountyslider-custom-nav-container-2 .owl-dots {
  display: flex;
  align-content: center;
}
.bountySlider .bountyslider-custom-nav-container-2 .owl-dot {
  cursor: pointer;
  background: 0 0;
  outline: 0;
  border: none;
}
.bountySlider .bountyslider-custom-nav-container-2 .owl-dot span {
  display: block;
  width: 25px;
  height: 5px;
  border-radius: 16px;
  background-color: #b4b4b4;
  margin: 5px 3px;
  box-sizing: border-box;
  transition: background-color 0.3s;
}
.bountySlider .bountyslider-custom-nav-container-2 .active span {
  background: #3A3A3B !important;
}
.bountySlider .bountyslider-custom-nav-container-2 .arrowButton {
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
}
.bountySlider .bountyslider-custom-nav-container-2 .smallArrow {
  width: 10px;
  height: 20px;
}
.bountySlider .bountyslider-custom-nav-container {
  width: 100%;
  position: absolute;
  bottom: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bountySlider .bountyslider-custom-nav-container .owl-prev,
.bountySlider .bountyslider-custom-nav-container .owl-next {
  z-index: 1;
  display: inline-block;
  cursor: pointer;
}
.bountySlider .sliderContainer {
  margin-top: 20px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1280px) {
  .bountySlider .sliderContainer {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}
.bountySlider .slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
}
.bountySlider .slide {
  box-sizing: border-box;
  text-align: center;
}
.bountySlider .slide .slideInner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 175px;
  height: 175px;
}
.bountySlider .slide .slideInner .slideImage {
  height: 115px;
  width: 115px;
}
.bountySlider .slide .slideInner .slideHeadline {
  height: 40px;
  width: 100%;
  display: inline-block;
  position: absolute;
  bottom: 10px;
}
.bountySlider .sliderButton {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.bountySlider .btnShow {
  display: block;
}
.bountySlider .btnHide {
  display: none;
}
.bountySlider #prevBtn {
  left: 0;
}
.bountySlider #nextBtn {
  right: 0;
}
.bountySlider .pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.bountySlider .pagination span {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px;
  background-color: #ddd;
  border: 1px solid #ccc;
}
.bountySlider .pagination .active {
  background-color: #666;
  color: white;
}

.trikotSlider h2 {
  text-align: center;
  font-size: 22px !important;
  white-space: nowrap;
}
.trikotSlider .owl-stage-outer {
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.trikotSlider .sliderBackground {
  width: 100%;
  height: 130px;
  position: absolute;
  top: 102px;
  background-image: url("../assets/trikotSliderBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.trikotSlider .slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.trikotSlider .slide .trikotImage {
  max-height: 150px;
  width: auto;
  transition: transform 0.3s ease;
  transform-origin: bottom;
}
.trikotSlider .slide .trikotImage:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.trikotSlider .trikotslider-custom-nav-container-2 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.trikotSlider .trikotslider-custom-nav-container-2 .owl-dots {
  display: flex;
  align-content: center;
}
.trikotSlider .trikotslider-custom-nav-container-2 .owl-dot {
  cursor: pointer;
  background: 0 0;
  outline: 0;
  border: none;
}
.trikotSlider .trikotslider-custom-nav-container-2 .owl-dot span {
  display: block;
  width: 25px;
  height: 5px;
  border-radius: 16px;
  background-color: #b4b4b4;
  margin: 5px 3px;
  box-sizing: border-box;
  transition: background-color 0.3s;
}
.trikotSlider .trikotslider-custom-nav-container-2 .active span {
  background: #3A3A3B !important;
}
.trikotSlider .trikotslider-custom-nav-container-2 .arrowButton {
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
}
.trikotSlider .trikotslider-custom-nav-container-2 .smallArrow {
  width: 10px;
  height: 20px;
}
.trikotSlider .trikotslider-custom-nav-container {
  width: 100%;
  position: absolute;
  bottom: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trikotSlider .trikotslider-custom-nav-container .owl-prev,
.trikotSlider .trikotslider-custom-nav-container .owl-next {
  z-index: 1;
  display: inline-block;
  cursor: pointer;
}

.miscellaneous {
  position: relative;
}
.miscellaneous .miscellaneous-inner {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 500px) {
  .miscellaneous .miscellaneous-inner {
    flex-direction: column;
  }
}
.miscellaneous .miscellaneous-inner .miscellaneousBox {
  width: 50%;
  max-height: 360px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #FFFFFF;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
@media screen and (max-width: 500px) {
  .miscellaneous .miscellaneous-inner .miscellaneousBox {
    width: 100%;
  }
}
.miscellaneous .miscellaneous-inner .miscellaneousBox h2 {
  margin: 0;
}
.miscellaneous .miscellaneous-inner .miscellaneousBox .conentImage {
  max-height: 190px;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (max-width: 1280px) {
  .miscellaneous .miscellaneous-inner .miscellaneousBox .conentImage {
    max-height: 140px;
  }
}
.miscellaneous .miscellaneous-inner .box1 {
  background-color: #24353F;
}
.miscellaneous .miscellaneous-inner .box1 span {
  font-weight: 300;
}
.miscellaneous .miscellaneous-inner .box2 {
  background-color: #00a139;
}
.miscellaneous .miscellaneous-inner .box2 span {
  font-weight: 300;
  font-size: 22px;
}
.miscellaneous .miscellaneous-inner .box2 .btnShop {
  border: 1px solid #FFFFFF;
}
.miscellaneous .miscellaneous-inner .box2 .btnShop:hover .btn-icon {
  transform: translateX(10px);
}
.miscellaneous .miscellaneous-inner .box2 .btnShop .btn-icon {
  margin-right: 0;
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}
.miscellaneous .imgFootballGuy {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 420px;
}
@media screen and (max-width: 768px) {
  .miscellaneous .imgFootballGuy {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .miscellaneous .imgFootballGuy {
    max-height: 340px;
  }
}

body {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #3A3A3B;
  font-family: "proxima-nova", sans-serif;
}

h1 {
  font-size: 38px;
  font-weight: 900;
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 18px;
  }
}

.fullsize-background {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: 130% auto;
  background-position: center calc(50% - 120px);
  background-image: url("../assets/top-background.png");
}
@media screen and (max-width: 1280px) {
  .fullsize-background {
    background-size: 120% auto;
    background-position: center calc(50% - 120px);
  }
}
@media screen and (max-width: 1000px) {
  .fullsize-background {
    background-size: 120% auto;
    background-position: center calc(50% - 150px);
  }
}
@media screen and (max-width: 768px) {
  .fullsize-background {
    display: none;
  }
}

.outer-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
}

.centered-content {
  position: relative;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 1240px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1280px) {
  .centered-content {
    width: 100%;
  }
}

.header-wrapper {
  display: block;
}

.description-wrapper {
  margin-top: 30px;
}

.bountySlider-wrapper {
  margin-top: 70px;
}
@media screen and (max-width: 768px) {
  .bountySlider-wrapper {
    margin-top: 0;
  }
}

.matches-wrapper {
  margin-top: 50px;
}

.trikotSlider-wrapper {
  margin-top: 50px;
}

.miscellaneous-wrapper {
  margin-top: 100px;
}
@media screen and (max-width: 1280px) {
  .miscellaneous-wrapper {
    margin-top: 50px;
  }
}

.footer-wrapper {
  width: 100%;
  margin-top: 40px;
  display: block;
}