
.header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .banner {
        position: relative;
        display: block;

        .bg-image {
            width: 100%;
            height: auto;
        }

        .logo {
            position: absolute;
            top: -20px;
            left: 50px;

            @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-l) {
                width: 120px; 
                top: -10px;
                left: 20px;
            }

            @media screen and (max-width: $breakpoint-s) {
                width: 80px; 
                top: -5px;
                left: 10px
            }
        }

        .headline {
            position: absolute;
            top: 130px;
            right: 50px;

            @media screen and (max-width: $breakpoint-s) { 
                top: 30px;
                right: 20px;
            }

            @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-m) { 
                top: 30px;
                right: 20px;
            }

            @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xl) {
                top: 90px;
            }

            .h-text{
                text-shadow: 0px 10px 10px #00000029;
                text-transform: uppercase;
                font-weight: $fontWeightBolder;
                color: $colorWhite;
                display: block;
            } 
            
            .h-text-small {
                font-size: $fontSizeBannerHeadline;  
               
                @media screen and (max-width: $breakpoint-s) { 
                    font-size: 16px; 
                }

                @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-m) { 
                    font-size: 20px; 
                }

                @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xl) {
                    font-size: 26px;  
                }
            }

            .h-text-big {
                font-size: $fontSizeBannerHeadlineBig;

                @media screen and (max-width: $breakpoint-s) { 
                    font-size: 20px; 
                }

                @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-m) { 
                    font-size: 30px;  
                }

                @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xl) {
                   font-size: 40px; 
                }
            }

            .line1 {
                text-align: left;
            }

            .line2 {
                text-align: center;
            }

            .line3 {
                text-align: right;
            }
        }
    }   
    
    .countdown {
        display: block;
        position: relative;
        top: calc(-4 * $padding);
        text-align: center;
        

        @media screen and (max-width: $breakpoint-s) { 
            top: 0;
        }   
        
        @media screen and (min-width: $breakpoint-s) and (max-width: $breakpoint-m) { 
            top: calc(-2 * $padding);
        }  


        img {
            width: 40%;
            max-width: 540px;

            @media screen and (max-width: $breakpoint-m) {
                width: 60%;
            }
        }

        h2 {
            margin-top: calc(-1 * $margin / 2);
            margin-bottom: 0;
        }

        span {
            font-weight: 300;
        }
    }
}