// Button 
.btn {
    display: flex;
    align-items: center;

    padding-top: calc($padding * 1);
    padding-bottom: calc($padding * 1);
    padding-left: calc($padding * 3);
    padding-right: calc($padding * 3);

    color: $colorWhite;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: $fontWeightBolder;
    background-color: $colorDTackGreenNormal;
    transition: background-color 200ms ease-in-out;

    &:hover {
        cursor: pointer;
        background-color: $colorDTackGreenDark;
    }

    .btn-icon {
        width: 22px;
        margin-top: 0;
        margin-left: 0;
        margin-right: $margin;
        margin-bottom: 0;
    }
}