.trikotSlider {
    h2 {
        text-align: center;
        font-size: 22px !important;
        white-space: nowrap;
    }

    .owl-stage-outer {
        height: 170px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }


    .sliderBackground {
        width: 100%;
        height: 130px;
        position: absolute;
        top: 102px;

        background-image: url('../assets/trikotSliderBackground.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: green;

        .trikotImage {
            max-height: 150px;
            width: auto;
            transition: transform 0.3s ease;
            transform-origin: bottom;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }

    }

    .trikotslider-custom-nav-container-2 {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .owl-dots {
            // margin-left: $margin;
            // margin-right: $margin;
            display: flex;
            align-content: center;
        }

        .owl-dot {
            cursor: pointer;
            background: 0 0;
            outline: 0;
            border: none;

            span {
                display: block;
                width: 25px ;
                height: 5px ;
                border-radius: 16px;
                background-color: #b4b4b4;
                margin: 5px 3px ;
                box-sizing: border-box;
                transition: background-color .3s;
            }
        }

        .active {
            span {
                background: $colorDTackGrayDark !important;
            } 
        }

        .arrowButton {
            background: 0 0;
            border: none;
            outline: 0;
            cursor:pointer;
        }

        .smallArrow {
            
            width: 10px;
            height: 20px;
        }
    }

    .trikotslider-custom-nav-container {
        width: 100%;
        position: absolute;
        bottom: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .trikotslider-custom-nav-container .owl-prev,
    .trikotslider-custom-nav-container .owl-next {
        z-index: 1;
        display: inline-block;
        cursor: pointer;
    }
}