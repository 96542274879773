.footer {
    background-color: $colorDTackBlue;
    padding: calc(2*$padding);
    color: $colorWhite;
    display: flex;
    justify-content: center;
    font-size: $fontSizeSmall;

    .inner {
        width: 1240px;

        @media screen and (max-width: $breakpoint-xxl) {
            width: 100%;
        }
    }
    
    .flex-container {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $breakpoint-m) {
            flex-direction: column;
        }

        .box {
            display: flex;
            flex-direction: row; 
            align-items: center;
            justify-content: flex-start;
            width: 50%;                   

            @media screen and (max-width: $breakpoint-m) {
                flex-direction: column;
                 width: 100%;
            }
        }        
    }


    .containerLogo {
        width: 85px;
        height: 25px;
        margin-right: $margin;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .containerCopyright {
        margin-right: $margin;

        @media screen and (max-width: $breakpoint-m) {
           margin-top: $margin;
           text-align: center;
        }
    }

    .containerEnglish {
        margin-right: $margin;
        cursor: pointer;
        width: 35px;
        height: 25px;

        @media screen and (max-width: $breakpoint-m) {
            margin-top: $margin;
         }

        a {
            text-decoration: none;
        }
    }

    .containerDisclaimer {
        @media screen and (max-width: $breakpoint-m) {
            margin-top: $margin;
         }
    }
}