@import 'variables';
@import 'shared';
@import 'header';
@import 'matches';
@import 'footer';
@import 'description';
@import 'bountySlider';
@import 'trikotSlider';
@import 'miscellaneous';
  

body {
    font-size: $fontSizeNormal;
    padding: 0;
    margin: 0;

    color: $colorDTackGrayDark;
    font-family: $font-family-sans-serif;
}

h1 {
    font-size: $fontSizeH1;
    font-weight: $fontWeightBolder;

    @media screen and (max-width: $breakpoint-m) {
        font-size: $fontSizeH1Mobile;
    }
}

h2 {
    font-size: $fontSizeH2;

    @media screen and (max-width: $breakpoint-m) {
        font-size: $fontSizeH2Mobile;
    }
}


.fullsize-background {
    width: 100%;
    height: 400px;

    background-repeat: no-repeat;
    background-size: 130% auto;
    background-position: center calc(50% - 120px);
    background-image: url('../assets/top-background.png');

    @media screen and (max-width: $breakpoint-xxl) {
        background-size: 120% auto;
        background-position: center calc(50% - 120px);
    }

    @media screen and (max-width: $breakpoint-xl) {
        background-size: 120% auto;
        background-position: center calc(50% - 150px);
    }

    @media screen and (max-width: $breakpoint-m) {
        display: none;
    }
}


.outer-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
}


.centered-content {
    position: relative;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0); 

    width: 1240px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-xxl) {
        width: 100%;
    }
}


.header-wrapper {
    display: block;
}

.description-wrapper {
    margin-top: calc(3*$margin);    
}


.bountySlider-wrapper {
    margin-top: calc(7*$margin);

    @media screen and (max-width: $breakpoint-m) {
        margin-top: 0;
    }
}


.matches-wrapper {
    margin-top: calc(5 * $margin);
}


.trikotSlider-wrapper {
    margin-top: calc($margin * 5);
}


.miscellaneous-wrapper {
    margin-top: calc($margin * 10);

    @media screen and (max-width: $breakpoint-xxl) {
        margin-top: calc($margin * 5);
    }
}


.footer-wrapper {
    width: 100%;
    margin-top: calc(4*$margin);
    display: block;
}