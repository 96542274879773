.matches {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .productImageContainer {
        position: relative;
    }

    .productImageContainerMobile {
        position: relative;
        margin: 0 !important;
    }

    .productImage {
        display: block;
        filter: grayscale(100%);
    }


    .headline {
        width: 100%;
    }

    .headline-desktop {
        display: block;

        @media screen and (max-width: $breakpoint-m) {
            display: none;
        }
    }

    .headline-mobile {
        display: none; 

        h1 {
            word-wrap: normal;
        }

        @media screen and (max-width: $breakpoint-m) {
            display: block;
        }
    }

    h1 {
        color: $colorDTackGreenNormal;
        margin: 0;
    }

    h2 {
        margin: 0;
    }

    .sperator {
        margin-top: $margin;
        display: flex;
        flex-direction: row;
        justify-content: center;

        hr {
            border-top: 1px solid $colorDTackGrayDark;
            margin-left: $margin;
            margin-right: $margin;
        }

        .line {
            width: 35%;

            @media screen and (max-width: $breakpoint-xl) {
                width: 45%;
            }
        }
    }

    .match {
        margin-top: calc($margin * 2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: calc($padding * 2);
        padding-bottom: calc($padding * 2);
        width: 100%;
        background-color: $colorBackgroundMatch;

        @media screen and (max-width: $breakpoint-l) {
            padding-bottom: 0;
        }

        span {
            display: block;
        }


        .text-desktop {
            display: block;

            @media screen and (max-width: $breakpoint-m) {
                display: none;
            }
        }

        .text-mobile {
            display: block;

            @media screen and (min-width: $breakpoint-m) {
                display: none;
            }
        }

        .matchTime {
            font-weight: 300;
        }


        .encounter {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: $margin;

            .container {
                display: flex;
                flex-direction: row;   
                align-items: center;     
            }

            .left {
                width: 45%;    
                justify-content: flex-end;        
            }

            .middle{
                width: 10%;
                min-width: 150px;
                justify-content: center;        
            }

            .right {
                width: 45%;
                justify-content: flex-start;        
            }


            .flag {
                height: 25px;
            }


            .land {
                font-weight: $fontWeightBolder;
                margin-left: $margin;
                margin-right: $margin;  
            }    

            .counterGoalContainer {
                font-size: 38px;
                font-weight: $fontWeightBolder;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 47px;
                height: 60px;
                margin-left: $margin;
                margin-right: $margin;
                background-color: $colorWhite;
                box-shadow: 0px 0px 10px #00000029;
            }

            .colonSeperator {
                font-size: $fontSizeColonSeperator;
                font-weight: $fontWeightBolder;
            }            
        }

        .bountys-desktop {

            @media screen and (max-width: $breakpoint-l) {
                display: none;
            }

            .bountyBox {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: calc(2*$margin);
                padding-left: 4*$padding;
                padding-right: 4*$padding;
                box-sizing: border-box;

                @media screen and (max-width: $breakpoint-xl) {
                    padding-left: $padding;    
                    padding-right: $padding;
                }

                .section {
                    padding: calc(2*padding);
                    box-sizing: border-box;
                    justify-content: space-between;
                    display: flex;
                    flex-direction: row;
                    width: 80px;

                    .productDescription {
                        width: 45%;
                        display: flex;
                        flex-direction: column;
                        text-align: left;

                        .colorGreen {
                            color: $colorDTackGreenNormal;
                        }

                        .colorGray {
                            color: #bdbdbd; 
                        }
    
                        .sectionHeadline {
                            font-size: $fontSizeNormal;
                            font-weight: $fontWeightBolder;
                        }

                        .subText {
                            font-weight: 300;
                        }
                    }


                    .codeContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        .smallText {
                            font-size: 11px;
                            font-weight: 300;  
                            margin-bottom: 5px;  
                        }

                        .popup {
                            visibility: hidden;
                            min-width: 70px;
                            background-color: $colorWhite;
                            font-size: 11px;
                            top: -20px;
                            left: 100px;
                            border: 1px solid $colorDTackGrayLight;
                            text-align: center;
                            border-radius: 5px; 
                            padding: 10px;
                            position: absolute;
                            z-index: 1;
                            transform: translateX(-50%);
                            font-weight: 300;
                        }
                        
                        .popup.show {
                            visibility: visible;
                            animation: fadeInOut 2s;
                        }
                        
                        @keyframes fadeInOut {
                            0%, 100% { opacity: 0; }
                            50% { opacity: 1; }
                        }

                        .codebox {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding:calc($padding/2) calc(2*$padding);
                            border: 2px dashed $colorDTackGrayDark;
                            font-weight: $fontWeightBolder;

                            img {
                                margin-left: $margin;
                                width: 14px;
                                height: 18px;
                                cursor: pointer;
                            }

                            
                        }
                    }                 
                }

                .hidden {
                    visibility: hidden;
                }

                .left {
                    padding-left: calc(2 * $padding);
                    padding-right: $padding;
                    padding-top: $padding;
                    padding-bottom: $padding;

                    @media screen and (max-width: $breakpoint-xl) {
                        padding-left: $padding;
                    }

                    .ballContainer {
                        display: flex;
                        align-items: center;
                        margin-right: calc(4*$margin);

                        @media screen and (max-width: $breakpoint-xl) {
                            margin-right: 0;
                        }
                    }
                }


                .right {
                    padding-left: $padding;
                    padding-right: calc(2 * $padding);
                    padding-top: $padding;
                    padding-bottom: $padding;

                    @media screen and (max-width: $breakpoint-xl) {
                        padding-right: $padding;
                    }

                    .ballContainer {
                        display: flex;
                        align-items: center;
                        margin-left: calc(4*$margin);

                        @media screen and (max-width: $breakpoint-xl) {
                            margin-left: 0;
                        }
                    }
                }

                .left, .right {
                    flex: 1;
                    background-color: $colorWhite;
                }

                .center {

                    display: flex;
                    justify-content: center;
                    width: 125px;
                    background-color: $colorWhite;
                    padding: 5px;
                    margin-left: 5px;
                    margin-right: 5px;

                    img {
                        width: 115px;
                        height: 115px;
                    }
                }
            }
        }


        .bountys-mobile {
            display: none;

            @media screen and (max-width:$breakpoint-l) {
                display: block;
            }

            .bountyBox {
                position: relative;
                display: flex;
                flex-direction: column;
                background-color: $colorWhite;
                box-sizing: border-box;
                margin: $margin;
                padding: $padding;
                align-items: center;

                .ballContainer {
                    img {
                        width: 27px;
                        height: 27px;
                    }
                }


                .productDescription {
                    width: 45%;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    margin-top: $margin;

                    .colorGreen {
                        color: $colorDTackGreenNormal; 
                    }

                    .colorGray {
                        color: #cecece; 
                    }

                    .sectionHeadline {
                        font-size: 13px;
                        font-weight: $fontWeightBolder;
                    }

                    .subText {
                        font-size: 13px;
                        font-weight: 300;
                    }
                }

                .bottomSectionWrapper {
                    display: flex;
                    flex-direction: row;
                    margin-top: calc($margin*3);

                    @media screen and (max-width: $breakpoint-s) {
                        flex-direction: column-reverse;
                        margin-top: $margin;
                        margin-bottom: $margin;
                    }

                    .codeContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        @media screen and (max-width: $breakpoint-s) {
                            margin-top: $margin;
                            flex-direction: row;
                        }

                        .popup {
                            visibility: hidden;
                            min-width: 70px;
                            background-color: $colorWhite;
                            font-size: 11px;
                            top: 20px;
                            left: 90px;
                            border: 1px solid $colorDTackGrayLight;
                            text-align: center;
                            border-radius: 5px; 
                            padding: 10px;
                            position: absolute;
                            z-index: 1;
                            transform: translateX(-50%);
                        }
                        
                        .popup.show {
                            visibility: visible;
                            animation: fadeInOut 2s;
                        }
                        
                        @keyframes fadeInOut {
                            0%, 100% { opacity: 0; }
                            50% { opacity: 1; }
                        }

                        .smallText {
                            font-size: 11px;
                            font-weight: 300;  
                            margin-bottom: $margin;

                            @media screen and (max-width: $breakpoint-s) {
                                margin-right: $margin;
                                margin-bottom: 0;
                            }
                        }

                        .codebox {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding:calc($padding/2) calc(2*$padding);
                            border: 2px dashed $colorDTackGrayDark;
                            font-weight: $fontWeightBolder;

                            img {
                                margin-left: $margin;
                                width: 14px;
                                height: 18px;
                            }
                        }
                    }

                    .imageContainer {
                        margin-left: calc(4* $margin);

                        img {
                            width: 100px;
                            height: 100px;
                        }

                        @media screen and (max-width: $breakpoint-s) {
                            margin-left: 0;
                        }
    
                    }
                }

            }
        }
    }
}