.bountySlider {
    width: auto;

    .headline {

        @media screen and (max-width: $breakpoint-xxl) {
            margin-left: calc(2*$margin);
            margin-right: calc(2*$margin);            
        }

        h2 {
            margin-top: 0;
            margin-bottom: 5px;
        }

        .lineContainer {
            display: flex;
            flex-direction: row;
            align-items: center;


            .thickLine {
                width: 10%;
                min-width: 100px;
                height: 5px;
                background-color: $colorDTackGrayDark;
            }

            .thinLine {
                width: 90%;
                height: 1px;
                background-color: $colorDTackGrayDark
            }
        }
    }

    .bounty {
        margin-top: calc($margin*2);
    }


    .owl-stage-outer {
        height: 160;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }


    .sliderBackground {
        width: 100%;
        height: 130px;
        position: absolute;
        top: 102px;

        background-image: url('../assets/trikotSliderBackground.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: green;

        .trikotImage {
            max-height: 150px;
            width: auto;
            transition: transform 0.3s ease;
            transform-origin: bottom;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }

    }

    .bountyslider-custom-nav-container-2 {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .owl-dots {
            // margin-left: $margin;
            // margin-right: $margin;
            display: flex;
            align-content: center;
        }

        .owl-dot {
            cursor: pointer;
            background: 0 0;
            outline: 0;
            border: none;

            span {
                display: block;
                width: 25px ;
                height: 5px ;
                border-radius: 16px;
                background-color: #b4b4b4;
                margin: 5px 3px ;
                box-sizing: border-box;
                transition: background-color .3s;
            }
        }

        .active {
            span {
                background: $colorDTackGrayDark !important;
            } 
        }

        .arrowButton {
            background: 0 0;
            border: none;
            outline: 0;
            cursor:pointer;
        }

        .smallArrow {
            
            width: 10px;
            height: 20px;
        }
    }

    .bountyslider-custom-nav-container {
        width: 100%;
        position: absolute;
        bottom: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bountyslider-custom-nav-container .owl-prev,
    .bountyslider-custom-nav-container .owl-next {
        z-index: 1;
        display: inline-block;
        cursor: pointer;
    }


    .sliderContainer {
        margin-top: calc(2*$margin);
        position: relative;
        width: 100%;
        overflow: hidden;

        @media screen and (max-width: $breakpoint-xxl) {
            width: calc(100% - 40px);    

            margin-left: calc($padding * 2);
            margin-right: calc($padding * 2);
        }
    }


    .slider {
        display: flex;
        transition: transform 0.5s ease-in-out;
    }
    
    .slide {
        //width: 25%;
        box-sizing: border-box;
        // border: 1px solid #ccc;
        text-align: center;

        .slideInner {
            //padding: $padding;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            min-width: 175px;
            height: 175px;

            .slideImage {
                height: 115px;
                width: 115px;
            }

            .slideHeadline {
                height: 40px;
                width: 100%;
                display: inline-block;
                position: absolute;
                bottom: 10px;
            }
        }    
    }
    
    .sliderButton {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        //background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
    }

    .btnShow {
        display: block;
    }

    .btnHide {
        display: none;
    }
    
    #prevBtn {
        left: 0;
    }
    
    #nextBtn {
        right: 0;
    }
    
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
    
    .pagination span {
        margin: 0 5px;
        cursor: pointer;
        padding: 5px;
        background-color: #ddd;
        border: 1px solid #ccc;
    }
    
    .pagination .active {
        background-color: #666;
        color: white;
    }
}