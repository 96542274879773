.description {
    // width: 100%;

    .top-wrapper {
        display: flex;
        flex-direction: column;

        .line-top {
            margin-bottom: 40px;
            border-top: 1px solid $colorDTackGrayDark;
        }    

        .cols {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: $breakpoint-m ) {
                flex-direction: column;
            }

            .col-1 {
                width: 36%; 

                @media screen and (max-width: $breakpoint-m ) {
                    width: auto; 
                    margin-left: calc(2*$margin);
                    margin-right: calc(2*$margin);
                    text-align: center;
                }
                
                @media screen and (min-width: $breakpoint-m ) and (max-width: $breakpoint-xxl ) {
                    width: 50%;
                    padding-left: calc($padding*2);
                    padding-right: $padding;
                }

                h1 {
                    margin: 0;
                }            
                
                .thinText {
                    font-weight: $fontWeightBase;
                }
            }

            .col-2 {
                width: 45%;

                @media screen and (max-width: $breakpoint-m) {
                    display: none;
                    margin-top: calc(2*$margin);
                    width: 100%; 
                }

                @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xxl) {
                    width: 50%;
                }

                .col-2-inner {
                    max-width: 510px;

                    @media screen and (max-width: $breakpoint-m) {
                        max-width: unset;
                        padding-left: 0;
                        padding-right: 0;

                        margin-left: calc(2*$margin);
                        margin-right: calc(2*$margin);
                    }

                    @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xxl ) {
                        max-width: unset;
                        padding-left: $padding;
                        padding-right: calc($padding * 2);
                    }

                    h2 {
                        margin-top: 0;
                        margin-bottom: $margin;
                        font-weight: $fontWeightBolder;
                    }

                    span {
                        font-weight: $fontWeightBase;
                    }
                }
            }

            .col-3 {
                width: 19%;
                position: relative;

                @media screen and (max-width: $breakpoint-xxl ) {
                    width: 0;
                }

                .imageJubeln {
                    position: absolute;
                    bottom: -30px;
                    left: -5px;

                    @media screen and (max-width: $breakpoint-xxl ) {
                        display: none;
                    }
                }
            }
        }
    }

    .flagLineContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;

        @media screen and (max-width: $breakpoint-m) {
            display: none;
        }

        .section {
            width: 33.33%;
            height: 10px;
        }

        .black {
            background-color: #000000;
        }

        .red {
            background-color: #cb2e25;
        }

        .gold {
            background-color: #ffc600;
        }
    }

    
    .disclaimer {
        font-weight: 300;
        margin-top: $margin * 2;
        color: $colorFontLightGray;

        @media screen and (max-width: $breakpoint-m) {
            display: none;
        }

        @media screen and (min-width: $breakpoint-m) and (max-width: $breakpoint-xxl) {
            margin-right: calc($margin * 2);    
            margin-left: calc($margin * 2);
        }
    }


    .wrapperAccordion {
        display: flex;
        flex-direction: column;
        margin-top: calc(2*$margin);

        @media screen and (min-width: $breakpoint-m) {
            display: none;
        }


        .accordion {
            margin: 0 auto;
            overflow: hidden;
        }
        
        .accordion-item {
        
        }
        
        .teaser {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;

            h2 {
                text-align: center;
                margin: 0;
            }

            img {
                margin-bottom: 20px;
                margin-top: 5px;
            }
        }
        
        .teaser p {
            margin: 0;
        }
        
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 120px, rgba(255, 255, 255, 1) 100%);
            pointer-events: none;
        }
        
        .content {
            display: none;
            padding: 0 20px;
            background: #fff;
            margin-bottom: 50px;
        }


        .flagLineContainerMobile {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 30px;
    
    
            .section {
                width: 33.33%;
                height: 10px;
            }
    
            .black {
                background-color: #000000;
            }
    
            .red {
                background-color: #cb2e25;
            }
    
            .gold {
                background-color: #ffc600;
            }
        }
    
        .disclaimerMobile {
            font-weight: 300;
            margin-top: $margin * 2;
            color: $colorFontLightGray;
        }
    }
}