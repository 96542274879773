.miscellaneous {
    position: relative;

    .miscellaneous-inner {
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $breakpoint-s) {
            flex-direction: column;
        }
        
        .miscellaneousBox {
            width: 50%;
            max-height: 360px;
            display: flex;
            flex-direction: column;
            padding: calc(2*$padding);
            color: $colorWhite;
            text-align: center;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;

            @media screen and (max-width: $breakpoint-s) {
                width: 100%;
            }

            h2 {
                margin: 0;
            }

            .conentImage {
                max-height: 190px;
                margin-top: 15px;
                margin-bottom: 15px;

                @media screen and (max-width: $breakpoint-xxl) {
                    max-height: 140px;
                }
            }
        }

        .box1 {
            background-color: $colorDTackBlue;

            span {
                font-weight: 300;
            }
        }

        .box2 {
            background-color: $colorDTackGreenNormal;

            span {
                font-weight: 300;
                font-size: $fontSizeH2;
            }

            .btnShop {
                border: 1px solid $colorWhite;

                &:hover {
                    .btn-icon {
                        transform: translateX(10px); 
                    }
                }

                .btn-icon {
                    margin-right: 0;
                    margin-left: $margin;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }


    .imgFootballGuy {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        max-height: 420px;

        @media screen and (max-width: $breakpoint-m){
            display: none;            
        }

        @media screen and (max-width: $breakpoint-xxl) {
            max-height: 340px;
        }
    }
}